export const DATE_FORMAT = "YYYY/MM/DD"
export const DATE_SEPARATOR = ' ~ '

export const DATERANGEPICKER_LOCALE = {
  "format": DATE_FORMAT,
  "separator": DATE_SEPARATOR,
  "applyLabel": "套用",
  "cancelLabel": "取消",
  "fromLabel": "From",
  "toLabel": "To",
  "customRangeLabel": "自訂",
  "weekLabel": "週",
  "daysOfWeek": [
      "日",
      "一",
      "二",
      "三",
      "四",
      "五",
      "六"
  ],
  "monthNames": [
      "一月",
      "二月",
      "三月",
      "四月",
      "五月",
      "六月",
      "七月",
      "八月",
      "九月",
      "十月",
      "十一月",
      "十二月"
  ],
  "firstDay": 1
}

export const AVAILABLE_DATE_FORMATS = ['YYYY/MM/DD', 'YYYY-MM-DD']

export const _parseDate = (dateString) => moment(dateString, AVAILABLE_DATE_FORMATS)

const _isObject = (val) => (val instanceof Object) && !Array.isArray(val)

const _isString = (val) => (typeof val) === 'string'

export const _linkTo = (destination, currentParams = true) => {
  if (_isObject(destination)) {
    const url = new URL(window.location)
    const params = new URLSearchParams(url.search)
    if (typeof currentParams === 'object') {
      params.forEach((val, key) => params.delete(key))
      Object.entries(currentParams).forEach(([ key, val ]) => {
        params.set(key, val)
      })
    }
    Object.entries(destination).forEach(([ key, val ]) => params.set(key, val))
    Turbo.visit(url.pathname + "?" + params.toString())

  } else if (_isString(destination)) {
    Turbo.visit(destination)

  } else {
    throw 'Not supported (yet)'

  }
}

export const DEFAULT_VALUES = {
  plaintext: { type: Boolean, default: false },
  autoSubmit: { type: Boolean, default: false },
  dateFormat: { type: String, default: 'yyyy/MM' },
  currentParams: Object,
  allowFuture: { type: Boolean, default: false }
}
