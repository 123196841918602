import { Controller } from "@hotwired/stimulus"
import AirDatepicker, { localeZh } from 'libs/air-datepicker'
import {
  DEFAULT_VALUES,
  _linkTo
} from './common'

const DATE_PARAM_FORMAT = 'yyyy-MM-DD'
const DATE_SEPARATOR = ' ~ '

// Connects to data-controller="date-picker--month"
export default class extends Controller {
  static targets = [ "visual", "begin", "end", "icon" ]
  static values = {
    ...DEFAULT_VALUES,
    range: { type: Boolean, default: false },
  }

  connect() {
    const dpOptions = {
      locale: localeZh,
      dateFormat: this.dateFormatValue,
      view: 'months',
      minView: 'months',
      inline: false,
      autoClose: true,
      multipleDatesSeparator: ' ~ ',
      range: this.rangeValue,
      selectedDates: this.isBeginEndBothExist()
        ? [this.beginTarget.value, this.endTarget.value]
        : false,
      maxDate: this.allowFutureValue ? "" : moment().format(DATE_PARAM_FORMAT),
      ...this.dpEvents()
    }
    this.datepicker = new AirDatepicker(this.visualTarget, dpOptions)
    this.updateVisualSize()
  }

  dpEvents() {
    return {
      onHide: (isFinished) => {
        if (isFinished && this.rangeValue && !this.datepicker.rangeDateTo) {
          this.resetDatepicker()
        }
      },
      onSelect: ({ date }) => {
        if (this.rangeValue) {
          this.updateBeginEnd(moment(date[0]), moment(date[1]))
          if (Array.isArray(date) && date.length === 2) {
            this.updateVisualSize()
            this.autoForward()
          }
        } else {
          this.updateVisual(moment(date))
          this.updateVisualSize()
          this.updateBeginEnd(moment(date), moment(date))
          this.autoForward()
        }
      },
    }
  }

  isBeginEndBothExist() {
    return this.beginTarget.value && this.beginTarget.value !== "" && this.endTarget.value && this.endTarget.value !== ""
  }

  resetDatepicker() {
    this.datepicker.selectedDates = [this.beginTarget.value, this.endTarget.value]
    this.datepicker.init()
  }

  updateVisualSize() {
    // NOTICE: hack 還是會有些微誤差
    if (this.plaintextValue) {
      this.visualTarget.style.width = this.datepicker._getInputValue(this.dateFormatValue).length + 'ch'
    }
  }

  updateVisual(date) {
    this.visualTarget.value = date.locale("zh-tw").format(this.dateFormatValue)
  }

  updateBeginEnd(beginDate, endDate) {
    this.beginTarget.value = beginDate.startOf('month').format(DATE_PARAM_FORMAT)
    this.endTarget.value = endDate.endOf('month').format(DATE_PARAM_FORMAT)
  }

  autoSubmit() {
    if (this.autoSubmitValue) { this.beginTarget.form.requestSubmit() }
  }

  autoLinkTo() {
    const params = {
      [this.visualTarget.name] : this.visualTarget.value,
      [this.beginTarget.name] : this.beginTarget.value,
      [this.endTarget.name] : this.endTarget.value
    }
    _linkTo(params, this.currentParamsValue)
  }

  autoForward() {
    this.plaintextValue ? this.autoLinkTo() : this.autoSubmit()
  }

  initVisual() {
    if (this.visualTarget.value === '' && this.beginTarget.value !== '') {
      this.visualTarget.value = moment(this.beginTarget.value, ['YYYY/MM/DD', 'YYYY-MM-DD']).format(this.dateFormatValue)
    }
  }

  manualDropdown() {
    this.visualTarget.focus()
  }
}
