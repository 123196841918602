import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="choices"
export default class extends Controller {
  static values = {
    shoudldSort: { type: Boolean, default: false },
  };

  initialize() {
    this.element.classList.add("choices-single");
    new Choices(this.element, {
      classNames: { containerOuter: "choices is-invalid" },
      shouldSort: this.shoudldSortValue,
    });
  }

  connect() { }
}
