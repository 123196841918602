import { Controller } from "@hotwired/stimulus"
import {
  DATERANGEPICKER_LOCALE,
  DATE_FORMAT,
  DATE_SEPARATOR,
  DEFAULT_VALUES,
  _parseDate,
  _linkTo
} from './common'

const defaultRanges = () => {
  let ranges = {}
  const startYear = moment().startOf('year')
  Array(12).fill(0).forEach((_, i) => {
    const month = startYear.clone().add(i, 'month')
    ranges[
      `${month.year()}, ${DATERANGEPICKER_LOCALE.monthNames[month.month()]}`
    ] = [
      month.clone().startOf('month'),
      month.clone().endOf('month')
    ]
  });
  return ranges
}

// Connects to data-controller="date-picker--date-range"
export default class extends Controller {
  static targets = [ 'visual', 'begin', 'end' ]
  static values = {
    ...DEFAULT_VALUES,
    defaultRange: Boolean,
    ranges: Object
  }

  beginTargetConnected() {
    const date = _parseDate(this.beginTarget.value)
    if (date.isValid()) { this.startDate = date }
  }

  endTargetConnected() {
    const date = _parseDate(this.endTarget.value)
    if (date.isValid()) { this.endDate = date }
  }

  connect() {
    const opts = {
      locale: DATERANGEPICKER_LOCALE,
      alwaysShowCalendars: true,
      startDate: this.startDate,
      endDate: this.endDate,
      autoApply: true,
      showCustomRangeLabel: false,
      drops: 'auto',
      showDropdowns: true,
      autoUpdateInput: false
    }
    // ranges
    if (this.defaultRangeValue) {
      opts['ranges'] = defaultRanges()
    } else if (Object.keys(this.rangesValue).length > 0) {
      opts['ranges'] = this.rangesValueToOptions()
    }
    // maxDate
    if (this.allowFutureValue === false) {
      opts['maxDate'] = moment()
    }
    this.datepicker = new DateRangePicker(this.element, opts, this.onApply.bind(this))
    this.datepicker.element.on('keyup.daterangepicker', this.onManualChanged.bind(this))

    this.setVisualSize()
    this.updateVisualInput(this.startDate, this.endDate)
  }

  rangesValueToOptions() {
    const _toOption = ([key, dates]) => [key, dates.map((date) => _parseDate(date))]
    return Object.fromEntries(Object.entries(this.rangesValue).map(_toOption))
  }

  formatDate(startDate = moment(), endDate = moment()) {
    return startDate.format(DATE_FORMAT) + DATE_SEPARATOR + endDate.format(DATE_FORMAT)
  }

  setVisualSize() {
    if (this.plaintextValue) {
      this.visualTarget.style.width = this.formatDate().length + 'ch'
    }
  }

  updateVisualInput(startDate, endDate) {
    if (this._isMoment(startDate) && this._isMoment(endDate)) {
      this.visualTarget.value = this.formatDate(startDate, endDate)
    } else {
      this.visualTarget.value = ''
    }
  }

  updateBeginAndEndInput(startDate, endDate) {
    this.beginTarget.value = startDate.format(DATE_FORMAT)
    this.endTarget.value = endDate.format(DATE_FORMAT)
  }

  onApply(startDate, endDate) {
    this.updateVisualInput(startDate, endDate)
    this.updateBeginAndEndInput(startDate, endDate)
    this.autoForward()
  }

  onManualChanged() {
    const dateString = this.visualTarget.value.split(DATE_SEPARATOR)
    if (dateString.length === 2) {
      this.updateBeginAndEndInput(
        moment(dateString[0], DATE_FORMAT),
        moment(dateString[1], DATE_FORMAT)
      )
    }
  }

  autoSubmit() {
    if (this.autoSubmitValue) { this.beginTarget.form.requestSubmit() }
  }

  autoLinkTo() {
    const params = {}
    params[this.visualTarget.name] = this.visualTarget.value
    params[this.beginTarget.name] = this.beginTarget.value
    params[this.endTarget.name] = this.endTarget.value
    _linkTo(params, this.currentParamsValue)
  }

  autoForward() {
    this.plaintextValue ? this.autoLinkTo() : this.autoSubmit()
  }

  _isMoment(date) {
    return typeof date != 'undefined' && date.isValid()
  }
}
